import React, { useContext, useEffect } from "react";
import UserContext from "../components/UserContext";
import Home_1 from "./home/home_1";
import Meta from "../components/Meta";
import { createThirdwebClient } from "thirdweb";
// import { createAuth } from "thirdweb/auth";
import { useActiveAccount } from "thirdweb/react";
export default function Home() {
  const { scrollRef } = useContext(UserContext);
  const  user  = useActiveAccount();

  const client = createThirdwebClient({
    clientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID, // get yours at https://thirdweb.com/dashboard/settings/api-keys
  });

  // const thirdwebAuth = createAuth({
  //   domain: "localhost:3000",
  //   client,
  // });

  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  }, [user]);

  return (
    <>
      <Meta />
      <Home_1 />
    </>
  );
}
